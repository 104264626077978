import GATSBY_COMPILED_MDX from "/opt/build/repo/content/pages-static/pustaki-wentylacyjne-2-kanałowe.mdx";
import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import Layout from "../components/layout";
import AnimationWrapper from "../components/animationWrapper";
import Button from "../components/button";
import Container from "../components/container";
import Header from "../components/header";
import Seo from "../components/seo";
import "./static-page.css";
const StaticPageTemplate = ({data, location, children}) => {
  const file = data.page.internal.contentFilePath.split('/').pop();
  const pageSlug = file.slice(0, file.lastIndexOf('.'));
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ⭐ Czytaj!`;
  const components = {
    Button: Button
  };
  return React.createElement(Layout, {
    location: location,
    path: pageSlug
  }, React.createElement(Seo, {
    title: `${data.page.frontmatter.title}`,
    index: data.page.frontmatter.index,
    description: metaDescription
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    data: data.page.frontmatter.header,
    slug: data.page.frontmatter.slug
  })), React.createElement(Container, null, React.createElement("div", {
    className: `static-page-content description-medium ${data.page.frontmatter.align}`
  }, React.createElement("div", {
    className: "section-description"
  }, React.createElement(MDXProvider, {
    components: components
  }, children)))));
};
export const pageQuery = graphql`
  query(
      $id: String!,
  ) {
    page: mdx(id: { eq: $id }) {
      id
      excerpt
      internal {
        contentFilePath
      }
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        align
      }
    }
  }
`;
StaticPageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object
  })
};
StaticPageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(StaticPageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
